import { ReactNode, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { cn } from '~/lib/utils';

type BodyProps = {
  children: ReactNode;
  className?: string;
};
const Body = ({ children, className }: BodyProps) => {
  const baseClassname = 'px-4 overflow-y-auto flex-1';
  const mergedClassName = cn(baseClassname, className);
  return (
    <div id="body" className={mergedClassName}>
      {children}
    </div>
  );
};

type FooterProps = {
  children: ReactNode;
};
const Footer = ({ children }: FooterProps) => (
  <div className="flex flex-col border-t p-4" id="footer">
    {children}
  </div>
);

export const CustomDialog = ({
  isOpen = false,
  children,
  onClose,
  className,
  closeClickingOutside
}: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
  closeClickingOutside?: boolean;
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose}>
        <Dialog.Backdrop
          className="fixed top-0 bottom-0 left-0 right-0 flex h-full w-full flex-col items-center justify-center z-30"
          style={{
            background: 'rgba(0,0,0, 0.3)',
          }}
          onClick={closeClickingOutside ? onClose : undefined}
        >
          <Dialog.Panel
            className={cn(
              'bg-white w-10/12 md:w-9/12 max-w-[1200px] flex flex-col rounded-lg md:max-h-[80%]',
              className,
            )}
          >
            {children}
          </Dialog.Panel>
        </Dialog.Backdrop>
      </Dialog>
    </Transition>
  );
};

CustomDialog.Footer = Footer;
CustomDialog.Body = Body;
